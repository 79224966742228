@import '../../../index.scss';

.Navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;

    .left-content {
        display: flex;
        align-content: center;

        .sidebar-button {
            display: flex;
            align-items: center;

            .text {
                font-size: 18px;
                padding: 0px 8px 0px 4px;
                color: $primaryColor;
                // width: 237px;
                text-align: start;
                margin-right: 25px;
            }
        }

        .vertical-line {
            width: 1px;
            height: 30px;
            background: #80808078;
            margin: 0px 8px;
        }

        .pi-question {
            color: $textColor;
        }

        .pano-type-dropdown {
            height: 30px;
            margin-right: 1em;
            width: 131px;

            .p-dropdown-label {
                padding-top: 4px;
            }
        }
    }

    .right-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .text {
            margin: 0px 8px;
            color: $textColor;
        }

        .search-input {
            border-color: $textColor;
            height: 30px;

            &:hover {
                border-color: $primaryColor;
            }
        }

        .logout-button {
            width: auto;
            color: $textColor;
            padding: 0.3em !important;

            img {
                margin-left: 5px;
            }

            &:hover {
                color: $textColor;
            }
        }
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}