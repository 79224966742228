@import '../../index.scss';

.import-dialog {
    .p-dialog-header {
        border-bottom: 0 none;
        // background: $primaryColor !important;
        color: white !important;
        padding: -1.5rem !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    }

    .p-dialog-content {
        // padding-top: 25px;
        min-width: 600px;

        .custom-choose-btn {
            color: $textColor !important;
            width: 105px !important;

            .pi-plus {
                display: none;
            }
        }

        .p-progressbar {
            display: none !important;
        }
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button,
    .p-fileupload-choose.p-button-danger {
        display: none;
    }

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {
        display: none;
    }

    .import-file-upload {
        .p-fileupload-content {
            min-width: 100% !important;
        }
    }

    .data-type-header {
        margin: 0 auto;
        font-size: medium;
        font-weight: 400;
    }

    .pair {
        display: flex;
        justify-content: space-between;
        // flex-direction: column-reverse;
    }

    .radio-button-group {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        height: 42px;
        padding-top: 10px;
    }

    .fieldset {
        width: 254px;
        border: lightgray solid 1px;
        /* border-width: thin; */
        border-radius: 6px;
    }

    .fileChooseDisabled {
        display: none !important;
    }



    //////
    .import-form {
        margin: 10px 0;

        .input-group {
            display: flex;
            flex-direction: column;
            margin: 20px 0;

            label {
                margin-bottom: 5px;
            }
        }

        .submit-button {
            display: flex;
            justify-content: end;

            .p-button {
                width: auto;
                padding: 10px !important;
                margin: 20px 0 0 0 !important;
                color: $primaryColor;
            }
        }

        .pi-times {
            color: $textColor;
        }
    }
}