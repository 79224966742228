@import '../../../../../index.scss';

.form {
    display: flex;
    flex-direction: column;

    .input-style {
        margin: 5px 0px 15px;
        min-width: 250px;
    }

    label {
        min-width: 180px;
        // margin: 10px 0;
    }

    .p-dropdown {
        border: 1px solid #ced4da !important;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 8px 0px;

        .p-button {
            width: auto !important;
            color: $primaryColor;
            padding: 5px 10px !important;
            margin: 2px !important;
        }

    }

    .p-fileupload-content {
        padding: 5px 0 !important;
    }

    .p-fileupload-choose {
        color: $primaryColor;
        background: transparent;
        border-color: $primaryColor;
        height: 35px;
        width: auto;
        margin: 8px 0px;
        padding: 0 12px 0 8px !important;

        &:hover {
            color: $primaryColor !important;
            background: transparent !important;
            border-color: $primaryColor !important;
        }
    }

    .attach {
        border: 1px solid #304562;
        border-radius: 3px;
        margin-top: 2px;
        padding: 14px;
        max-width: 284.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 5px !important;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50px;

            a {
                text-decoration: underline;
                color: $primaryColor;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .digi-screenshot-img,
        img {
            height: 85px;
            width: 70px;
        }
    }
}

.item-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        max-width: 70px !important;
    }
}

.digi-popup {
    min-width: 350px;
}

.attach-confirm-dialog {
    button {
        width: auto !important;
        color: $primaryColor;
        padding: 5px 10px !important;
        margin: 2px !important;
        border-color: $textColor !important;
    }
}

.p-fileupload-content {
    border-radius: 3px;
    max-width: 284.5px;

    .item-group {
        img {
            height: 85px;
            width: 70px;
        }
    }
}

.p-fileupload-content>.p-progressbar {
    display: none;
}