@import '../../index.scss';

.home {
  width: 100%;
  height: 100%;

  .navbar {
    width: 100%;
    padding: 10px;
    text-align: center;
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
    z-index: 1;
    position: relative;
    background-color: $lightbackgroundColor;
  }

  .containers {
    display: flex;
    height: calc(100% - 50px);
  }

  .containers-table-opened {
    height: calc(100% - 320px) !important;
  }

  .table {
    // // height: 0;
    // // visibility: hidden;
    // width: 100%;
    // transition: 0.2s;
    // position: absolute;
    // bottom: 45px;
    // z-index: 100;
    height: 0;
    visibility: hidden;
    transition: .2s;
  }

  .table-opened {
    visibility: visible;
    position: absolute;
    width: 100%;
    bottom: 45px;
    z-index: 90;
    height: 255px;
    tbody {
      overflow-y: auto !important;
    }
  }

  .map-container {
    // height: calc(100vh - 89.5px);
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;

   /*  .legend-container {
      display: none;
      position: absolute;
      bottom: 20px;
      right: 10px;
      background-color: $primaryColor;
      z-index: 3;
      padding: 7px;
      height: 11%;
      border-radius: 10px;
      // width: 18%;
      // max-width: 145px;
      // min-width: 145px;
    } */
  }

  .legend-container {

    .legend {
      position: fixed;
      // right: -114px;
      // bottom: 158px;
      right: -76px;
      bottom: 120px;
      width: auto;
      height: 32px;
      z-index: 1;
      display: flex;
      align-items: center;
      color: $secondaryColor;
      transform: rotate(-90deg);
      transition: .4s;
      cursor: pointer;
      opacity: .9;
      border-radius: 6px 6px 0 0px;
    }

    .tabmenu-head {
      background: $primaryFocusColor;
      position: fixed;
      right: 0;
      bottom: 15px;
      width: 32px;
      height: 32px;
      z-index: 1;
      justify-content: center;
      display: flex;
      align-items: center;
      color: $secondaryColor;
      transform: rotate(270deg);
      transition: 0.4s;
      cursor: pointer;
      opacity: .9;
    }

    .p-tabmenu {
      height: 32px;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      background: $primaryFocusColor !important;
      color: $secondaryColor;
      border-left: 2px solid $primaryColor;
      border-radius: 0;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background: $primaryColor !important;
      color: $secondaryColor;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      height: 32px;
    }

    .p-tabmenu-nav .p-menuitem-text {
      padding-bottom: 4px;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
      box-shadow: none;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      color: $secondaryColor;
    }
  }
  .map-container-panoclose {
    // height: calc(100vh - 51px);
    height: 100%;
    position: relative;
    width: 100%;
  }

  .panorama-container {
    // height: calc(100% - 10px);
    height: 100%;
  }

  .panorama-container2 {
    // height: calc(100% - 10px);
    height: 100%;
  }

  .panorama-container-with-table-opened {
    // height: calc(100% - 450px);
    height: 100%;
  }

  .panorama-container2-with-table-opened {
    // height: calc(100% - 450px);
    height: 100% !important;
  }

  .invisible-panorama-container {
    display: none;
  }

  .close-multi-display {
    display: none;
  }

  .p-splitter-gutter {
    z-index: 2;
    background-color: $secondaryColor;

    .p-splitter-gutter-handle {
      background-color: $secondaryColor;
    }
  }

  .multidisplay-pano-splitter {
    .p-splitter-panel {
      height: calc(100vh - 91px);
    }
  }

  .multidisplay-and-miniscreen-pano-splitter {
    height: 100% !important;
  }

  .multi-splitter-layout-close {
    visibility: hidden !important;
  }

  .mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }

  .mini-screen-table-opened {
    z-index: 10;
    position: absolute;
    right: 1px !important;
    bottom: 300px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }

  .screen-switch-button {
    display: flex;
    width: 30px;
    height: 30px;
    background-color: #20202a;
    border: 1px solid #e5e5e5;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, .75);
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 15;
    cursor: pointer;
  }

  .selected-layer {
    background: $secondaryColor;
    border: 1px solid $primaryColor;
    border-radius: 6px;
    position: absolute;
    top: 56px;
    left: 16px;
    pointer-events: all;
    overflow: auto;
    padding: 5px 10px;
    color: white;
  }

  .is-map-mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }

  .is-pano-mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }


    // /* Track styling for WebKit browsers */
    // input[type="range"]::-webkit-slider-runnable-track {
    //     height: 1px;
    //     background: #ccd7da;
    //     border: none;
    // }

    // /* Track styling for Firefox */
    // input[type="range"]::-moz-range-track {
    //     height: 1px;
    //     background: #ccd7da;
    //     border: none;
    // }

    /* Custom arrow thumb for WebKit browsers */
    input[type="range"]::-webkit-slider-thumb {

      -webkit-appearance: none; /* Override default look */
      appearance: none;
      // margin-top: -48px; /* Centers thumb on the track */
      background-color: #889ea5; 
      border-radius: 0.5rem;
/*       height: 4rem;
      width: 1rem; */

      margin-left: 8px;
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: none;
      height: 40px;
      width: 40px;
      cursor: pointer;
      // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8 5l7 7-7 7" stroke="%23333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 24 24"><path d="M7 4l8 8-8 8" stroke="%231e2c38" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 4l8 8-8 8" stroke="%231e2c38" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');

      background-repeat: no-repeat;
      background-size: contain;
    }

    /* Custom arrow thumb for Firefox */
    input[type="range"]::-moz-range-thumb {
      background-color: #889ea5;
      border: none; /*Removes extra border that FF applies*/
      border-radius: 0.5rem;
   
      margin-left: 8px;
      background-color: transparent;
      border: none;
      height: 40px;
      width: 40px;
      cursor: pointer;
      // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8 5l7 7-7 7" stroke="%23333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8 5l7 7-7 7" stroke="%23333" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 24 24"><path d="M7 4l8 8-8 8" stroke="%231e2c38" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/><path d="M11 4l8 8-8 8" stroke="%231e2c38" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/></svg>');

      background-repeat: no-repeat;
      background-size: contain;
    }

    /* Optional: Change track color as the slider value changes */
    input[type="range"]::-webkit-slider-runnable-track {
        background: linear-gradient(to right, #607880 0%, #607880 var(--value, 50%), #ccd7da var(--value, 50%), #ccd7da 100%);
    }

    // /* CSS Variable for track fill (requires JS to update) */
    // input[type="range"] {
    //     --value: 50%; /* Default value */
    // }





  /*********** Baseline, reset styles ***********/
  input[type="range"] {
    width: 100%;
    height: 1px;
    background: #ccd7da;  /* Track color */
    outline: none;


    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 25.5rem;
  }
  
  /* Removes default focus */
  input[type="range"]:focus {
    outline: none;
  }
  
  /******** Chrome, Safari, Opera and Edge Chromium styles ********/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: #add8e6;
    border-radius: 12.5rem;
    height: 0rem;
  }
  

  
  input[type="range"]:focus::-webkit-slider-thumb {
    outline: 3px solid #889ea5;
    outline-offset: 0.125rem;
  }
  
  /*********** Firefox styles ***********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: #add8e6;
    border-radius: 12.5rem;
    height: 0rem;
  }
  
  /* slider thumb */
  // input[type="range"]::-moz-range-thumb {
  //   background-color: #889ea5;
  //   border: none; /*Removes extra border that FF applies*/
  //   border-radius: 0.5rem;
  //   height: 4rem;
  //   width: 1rem;
  // }
  
  input[type="range"]:focus::-moz-range-thumb{
    outline: 3px solid #889ea5;
    outline-offset: 0.125rem;
  }
}