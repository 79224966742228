@import '../../index.scss';

#root {
    .back-drop {
        z-index: 99999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed;
        width: 100%;
        width: 100vw;
        height: 100%;
        height: 100vh;
        cursor: crosshair;
    }

    .canvas {
        width: 100%;
        height: 100%;
    }

    .snipping-bar {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        background-color: $secondaryColor;
        align-items: center;
        display: none;
        padding: 8px;
        border-radius: 5px;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);

        input {
            border-color: $borderColor;
            cursor: text;
            height: 30px;
        }

        .pi-times {
            color: $textColor;
        }

        a {

            .pi-download,
            .pi-upload {
                color: $textColor;
            }
        }

        div {
            cursor: pointer;
            width: 25px;
            height: 25px;
            padding: 3px;
            border-radius: 4px;
            margin: 5px;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:hover {
                border: 1px solid red;

                path.fill {
                    fill: $textColor;
                }
            }

            path.stroke {
                fill: $textColor;
                opacity: 0.7;
            }
        }
    }
}