@import '../../../index.scss';

.footer-content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 44px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    background-color: $secondaryColor;
    border-top: 1px solid #f5f5f5b3;
    z-index: 100;

    .left {
        display: flex;
        width: 50%;

        .label {
            margin: 0px 10px;
        }
    }

    .right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: center;

        .coordinate {
            display: flex;
            width: 302px;
        }
    }

}

.footer-content2 {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    bottom: -59px;
    left: -1215px;
    height: 45px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    background-color: $secondaryColor;
    border-top: 1px solid #f5f5f5b3;

    .left {
        display: flex;
        width: 50%;

        .label {
            margin: 0px 10px;
        }
    }

    .right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: center;

        .coordinate {
            display: flex;
            width: 302px;

        }
    }

}

.footer-content3 {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    bottom: -313px;
    left: -1094px;
    height: 45px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    background-color: $secondaryColor;
    border-top: 1px solid #f5f5f5b3;

    .left {
        display: flex;
        width: 50%;

        .label {
            margin: 0px 10px;
        }
    }

    .right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: center;

        .coordinate {
            display: flex;
            width: 302px;

        }
    }

}

.footer-content4 {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    bottom: 0 !important;
    left: 0 !important;
    height: 45px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    background-color: $secondaryColor;
    border-top: 1px solid #f5f5f5b3;

    .left {
        display: flex;
        width: 50%;

        .label {
            margin: 0px 10px;
        }
    }

    .right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        align-items: center;

        .coordinate {
            display: flex;
            width: 302px;

        }
    }

}

.text {
    color: $textColor !important;
}