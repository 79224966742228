@import "../../index.scss";
.Legend {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: end;
    // max-width: 70px;
    width: fit-content;
    position: absolute;
    right: 0;
    height: auto;
    bottom: 30px;
    flex-direction: column;
    opacity: 0.85;
    padding: 0 5px 5px 0px;
    border-radius: 6px; 
    z-index: 99999999999999;
    max-height: calc(100% - 194px);  //500px; /* Increased height to accommodate more legend items */
    background-color: transparent;
    background: none;


    top: 23%  !important; // 244px
    bottom: 1% !important;  // 344px
    background-color: transparent !important;
    background: none !important; 
    // font-size: 1px !important; 
    // max-height: calc(100% - 142px) !important;
    // height: calc(100% - 142px) !important;


}
.legend-title {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    right: 14px;
    top: 140px;  // Adjust this value as needed
    background-color: #007bffaa;
    color: white;
    padding: 6px 12px;
    border-radius: 5px;
    font-weight: bold;
    z-index: 999;
    width: fit-content;
    text-align: center; /* Centers the text horizontally */
    margin: auto;       /* Ensures proper alignment within the container */
    font-size: 1.1em;   /* Adjust font size if needed */
   // font-weight: bold;  /* Optional: make the title bold */
}

.Legend .lejant-info {
    flex: 0 0 auto; /* Allow items to wrap vertically */
    background-color: #007bffaa;
    color: white;
    padding: 4px;
    text-align: center;
    border-radius: 5px;
    // font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    // max-width: 136px;
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: start;
    

    // position: relative; /* Change to relative positioning */
    // width: fit-content; /* Let content determine width */
    max-width: calc(100vw - 20px); /* Prevent overflow beyond screen width */
    // margin-left: auto; /* Push boxes to the right */
}

.Legend .lejant-info:nth-child(2n) {
    margin-left: 0;
    margin-right: 10px;
}
#legend{
    max-width: 120px;
}

.cluster-Legend {
    width: 220px;
    height: 183px;
    display: flex;
    position: absolute;
    right: 35px;
    bottom: 45px;
    flex-direction: column;
    justify-content: space-evenly;
    opacity: 0.85;
    padding: 7px 0px 0px 8px;
    border-radius: 6px;
}

.cluster-Legend .lejant-info {
    display: flex;
    align-items: center;
}

.cluster-Legend .lejant-info .color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $secondaryColor;
    margin-right: 5px;
}

.cluster-Legend .lejant-info .color-info {
    color: $secondaryColor;
}


