// Font Import
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap');

// Variables
$font-family-base: 'Be Vietnam Pro', sans-serif;

// Colors
$colors: (
  'bg-primary': #1a2332,
  'bg-secondary': #232d3f,
  'text-primary': #ffffff,
  'text-secondary': #e5e7eb,
  'text-muted': #6b7280,
  'border': #374151,
  'button-primary': #3b82f6,
  'button-hover': #2563eb,
  'input-focus': rgba(59, 130, 246, 0.2)
);

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin input-base {
  width: 100%;
  background-color: map-get($colors, 'bg-primary');
  border: 1px solid map-get($colors, 'border');
  color: map-get($colors, 'text-primary');
  padding: 0.75rem -0.25rem 0.75rem 0.75rem;// 0.75rem;
  border-radius: 0.375rem;
  font-family: $font-family-base;
  
  &:focus {
    border-color: map-get($colors, 'button-primary');
    box-shadow: 0 0 0 2px map-get($colors, 'input-focus');
  }

  &::placeholder {
    font-family: $font-family-base;
    color: map-get($colors, 'text-muted');
  }
}

.login {
  min-height: 100vh;
  background-color: map-get($colors, 'bg-primary');
  @include flex-center;
  padding: 2rem;
  font-family: $font-family-base;

  .login_container {
    background-color: map-get($colors, 'bg-secondary');
    border-radius: 1rem;
    width: 100%;
    max-width: 980px;
    display: flex;
    overflow: hidden;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.55);
    max-height: 580px;
    height: 530px;
    .left {
      flex: 1;
      padding: 4rem;
      @include flex-column;
      align-items: center;
      background-color: rgb(31, 43, 59);
      border-right: 1px solid rgb(69, 81, 97);
      max-width: 41%;
      .top {
        margin-bottom: 3rem;
        width: 100%;
        @include flex-center;
        
        img {
          width: 280px;
          object-fit: contain;
        }
      }

      .line {
        width: 100%;
        max-width: 400px;
        margin-bottom: 1.5rem;
        
        label {
          display: block;
          margin-bottom: 0.5rem;
          color: map-get($colors, 'text-secondary');
          font-size: 0.875rem;
          font-weight: 500;
        }
        
        .p-inputtext,
        .p-password {
          @include input-base;
        }
      }

      .confirm {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2.5rem;
        
        label {
          // color: map-get($colors, 'text-secondary');
          // font-size: 0.875rem;
          // font-weight: 500;
          // display: flex;
          // align-items: center;
          // gap: 0.5rem;
          color: #e5e7eb;
          font-size: 0.875rem;
          font-weight: 400;
          display: flex;
          align-items: center;
        }

        .p-checkbox {
          margin: 0.1rem 0rem 0.1rem 1rem;
          color: #232d3f;
        }
      }

      .login-button {
        width: 100%;
        max-width: 400px;

        
        .p-button {
          height: 50px;
          right: 10px;
          width: 100%;
          background-color: #2598f6 !important;
          color: map-get($colors, 'text-primary');
          padding: 0.75rem -0.25rem 0.75rem 0.75rem;//0.75rem; 
          border-radius: 0.375rem;
          border: none;
          font-size: 20px;
          font-weight: bold;
          font-family: $font-family-base;
          cursor: pointer;
          transition: all 0.2s ease;
          
          &:enabled:hover {
            background-color: transparent;
          }
        
          &:focus {
            box-shadow: none;
            background-color: transparent;
          }
        
          &:active {
            box-shadow: none;
            background-color: transparent !important;
          }

        }

   

        
      }
    }

    .right {
      flex: 1;
      // background-color: map-get($colors, 'bg-primary'); 
      background-color: rgb(31, 43, 59);
      padding: 3rem;
      @include flex-column;
      justify-content: center;
      .title {
        color: map-get($colors, 'text-primary');
        font-size: 3rem;
        font-weight: 700;
        // margin-bottom: 0.5rem;
        margin-bottom: -0.90rem;
        line-height: 1.0;
      }

      .subtitle {
        color: map-get($colors, 'text-secondary');
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 4rem;
      }

      .portal-info {
        h3 {
          color: map-get($colors, 'text-primary');
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1rem;
        line-height: 1.0;

        }
        
        p {
          color: map-get($colors, 'text-secondary');
          font-size: 13px;
          line-height: 1.625;
          margin-bottom: 1rem;
          font-weight: 400;
        }
      }
    }
  }
}

// Toast Customization
.p-toast {
  .p-toast-message {
    font-family: $font-family-base;
    
    .p-toast-message-content {
      padding: 1rem;
      
      .p-toast-message-text {
        font-size: 0.875rem;
      }
    }
  }
}

// Responsive Breakpoints
@media (max-width: 1024px) {
  .login {
    padding: 1rem;
    
    .login_container {
      flex-direction: column;
      
      .left, .right {
        padding: 2rem;
      }
    }
  }
}

@media (max-width: 640px) {
  .login {
    padding: 0;
    
    .login_container {
      border-radius: 0;
      min-height: 100vh;
      
      .left, .right {
        padding: 1.5rem;
      }
      
      .top img {
        width: 160px;
      }

      .right {
        .title {
          font-size: 1.5rem;
        }

        .subtitle {
          font-size: 1rem;
        }
      }
    }
  }
}



