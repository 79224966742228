@import '../../../../../index.scss';

.buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 8px 0px;

    .p-button{
        width: auto !important;
        color: $primaryColor;
        padding: 5px 10px !important;
        margin: 2px !important;
    }

}

.form .input-style {
    margin: 5px 0px 15px;
    min-width: 100px !important;
}