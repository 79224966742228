@import '../../index.scss';

.Basemap {
  padding: 14px;
  display: flex;
  // align-items: flex-end;
  user-select: none;
  z-index: 100;
  text-align: center;

  // bottom: 20px;
  left: 20px;
  // z-index: 1;
  // width: 62px;
  // height: 62px;
  // cursor: pointer;
  // overflow: hidden;
  border-radius: 5px;
  transition: width 0.5s, height 1s;
  // box-shadow: 1px 3px 12px 2px #000000e0;
  // background-color: rgba(32, 32, 42, 0.91);
  // color: #ffffff;


  .basemap-button {
    margin-right: 6px;
    display: flex;
    text-align: center;
    // flex-direction: column;
    transition: width 0.5s, height 1s;
    img {
      width: 55px;
      height: 55px;
      border: 2px solid $primaryColor;
      border-radius: 6px;
    }
    .default {
      width: 55px;
      height: 55px;
      border: 2px solid #0071de;
      border-radius: 6px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .span-els{
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 50px;
    white-space: nowrap
  }
  .span-els:hover{
    // font-size: 10px;
    // overflow: visible;
    // float: left;
    // clear: left;
    // display:inline;
    // width: 55px
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
  }


  .basemap-group {
    display: flex;
    // justify-content: center;
    padding: 0;
    border-radius: 6px;
    height: 66px;//55px;
    // padding-bottom: 14px;
    
    .basemap {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      // width: 60px;
      margin: 0px 5px;

      .image-box {
        width: 55px;
        height: 55px;
        border: 2px solid $primaryColor;
        border-radius: 6px;

        &:hover {
          cursor: pointer;
        }
      }
    
      .title {
        font-size: 10px;
        padding: 0px 8px;
        margin: 2px;
        text-align: center;
        line-height: 15px;
      }
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    // background-color: black;
    // color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 113%;
    left: 50%;
    margin-left: -60px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: $thirdColor;//#304562;;
    color: #f5f5f5d2;
    border: 1px solid #f5f5f5af;
    padding: 3px 5px !important;

  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #2525256c transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}