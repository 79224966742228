@import '../../../index.scss';

.card {
    pointer-events: all;
    // background: $secondaryColor;

    .p-datatable-header {
        padding: 10px 10px 10px 2px !important;
        position: sticky;
        display: flex;
        align-items: center;
        // background: $secondaryColor;

        .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .group {
                display: flex;
                align-items: center;

                .p-button {
                    width: auto;
                    padding: 5px 7px !important;
                    background: transparent !important;
                }

                p {
                    margin: 0 10px 0 0;
                    padding: 4px 10px;
                    border-right: 2px solid #afafaf;
                }

                .download-button {
                    margin: 0 10px;
                }

            }

            .pi-times {
                cursor: pointer;
            }

            .pi-download {
                color: $primaryColor;
            }
        }
    }

}

.p-column-filter-operator {
    .p-dropdown {
        display: flex;
        align-items: center;
        height: 36px !important;
        width: 180px;
    }
}

.p-column-filter-constraint {
    .p-dropdown {
        display: flex;
        align-items: center;
        height: 36px !important;
        width: 180px;
    }

    .p-inputtext {
        width: 180px;
    }
}

.p-column-filter-add-rule {
    display: none;
}

.p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 0 1.25rem 1.25rem 1.25rem;
    justify-content: flex-end !important;

    button {
        display: flex;
        justify-content: center;
        width: 65px !important;
        height: 28px !important;
        margin-left: 6px;
        background-color: $primaryColor;
        color: $secondaryColor;
        border: 1px solid $primaryColor;

        &:hover {
            background-color: $primaryColor;
            color: $secondaryColor;
            border: 1px solid $primaryColor;
        }

        span {
            text-align: center !important;
            font-size: 13px;
            color: $textColor;
        }
    }

    .p-button.p-component.p-button-outlined {
        background-color: $secondaryColor ;
        color: $primaryColor !important;
        border: 1px solid $primaryColor;
        //    &:hover{
        //     background-color:  $secondaryColor ;
        //     color: $primaryColor !important;
        //     border: 1px solid $primaryColor;         
        // }
    }
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #42637e;
}

.test {
    width: 5px !important;
}

.p-datatable .p-sortable-column.p-highlight {
    color: $textColor;
    background-color: #4a708f;

    &:hover {
        color: $textColor !important;
        background: rgb(64 115 159);
    }
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $textColor !important;
}

.p-datatable .p-sortable-column:focus {
    // box-shadow: inset 0 0 0 1px $primaryHoverColor;
}

.p-column-filter-menu-button:focus {
    // box-shadow: 0 0 0 0.2rem $primaryHoverColor;
}

.p-link:focus {
    // box-shadow: 0 0 0 0.2rem $primaryHoverColor;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    // color: $secondaryColor !important;
    // background: $primaryColor !important;
}

// .edit-delete{
//     // cursor: pointer;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     // width: 100%;
//     // height: 50px;
//     padding: 0px 11px;
//     width: 100px !important;
//     min-width: 100px !important;
//     max-width: 100px !important;
//     height: 25px !important;
//     min-height: 25px !important;
//     max-height: 25px !important;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     margin: 0;
// }
.p-paginator-top {
    background: transparent !important;
    border: none;
    border-width: 0 !important;
    // border-top: 1px solid #dee2e6 !important;
    padding-top: 7px !important;
    position: absolute;
    right: 40px;
    top: 0;

    .p-dropdown {
        display: none;
    }
}

.p-frozen-column {
    // position: sticky;
}