@import '../../../index.scss';

.Sidebar1 {
  background: $secondaryColor;
  border: 1px solid $primaryColor;
  border-radius: 6px;
  position: absolute;
  bottom: 56px;
  right: 16px;
  max-width: 335px;
  pointer-events: all;
  max-height: 445px;
  // width: 248px;
  width: auto;
  overflow: auto;
  color: #000000;
  z-index: 9999;
  .line {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    margin-left: 10px;

    .p-treenode-label {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;

      b {
        font-weight: 400 !important;
      }
    }
  }

  .selected-line {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    background: #485f76 !important;
    border-radius: 5px;
    margin: 0px 10px;

    .p-treenode-label {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;

      b {
        font-weight: 400 !important;
      }
    }
  }

}

.p-treenode-icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.p-treenode-leaf {
  .p-tree-toggler {
    width: auto !important;
    display: none;
  }
}

.p-treenode-content {
  .p-checkbox {
    order: 3;
    margin-left: auto;
  }

  .p-treenode-label {
    text-align: start;
  }
}

.label {
  padding: 5px;
  text-align: start;

  b {
    font-weight: 400;
    color: $textColor;
  }
}

.p-tree-toggler {
  margin: 0 !important;
}

.disabled {
  pointer-events: none !important;
}

.raster-icon {
  background-image: url('../../../../public/icons/lidar.svg')
}