@import '../../index.scss';

.export-dialog {
    .p-dialog-header {
        border-bottom: 0 none;
        // background: $primaryColor !important;
        color: white !important;
        padding: -1.5rem !important;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        .pi {
            color: white;
        }
    }
    .p-dialog-content {
        padding-top: 25px;
        width: 600px;

        .p-button {
            color: white !important;
            width: 105px !important;
        }

        .p-progressbar {
            display: none !important;
        }
    }

    .p-button.p-button-danger,
    .p-buttonset.p-button-danger>.p-button,
    .p-splitbutton.p-button-danger>.p-button,
    .p-fileupload-choose.p-button-danger {
        display: none;
    }

    .p-button.p-button-success,
    .p-buttonset.p-button-success>.p-button,
    .p-splitbutton.p-button-success>.p-button,
    .p-fileupload-choose.p-button-success {
        display: none;
    }
    .custom-choose-btn {
        border-radius: 6px !important;
        background: $primaryColor;
        .pi-plus {
            display: none;
        }
    }
    .data-type-header {
        margin: 0 auto;
        font-size: medium;
        font-weight: 400;
    }
    .pair{
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .info{
            margin-top: 1%;
            margin-bottom: 18px;
            display: flex;
            font-style: italic;
            font-size: 18px;
            color: #ffa500;
            height: 18px;
            .leftdiv{
                width: 240px; //200px;
                text-align: right;
            }
            .space{
                width: 18px;
                margin-left: 2px;
            }
          }
        .item {
            display: flex;
            flex-direction: row;
            padding-bottom: 15px;
            justify-content: space-around;
        
            .item-header {
              font-size: large;
              font-weight: bold;
            }
        
            label {
              user-select: none;
              margin: 5px 0;
              display: flex;
              justify-content: space-between;
              line-height: 30px;
              cursor: pointer;
              font-size: larger;
            }
            textarea {
              resize: vertical;
              width: 100%;
            }
          }

          
        .epsg-item{
            display: flex;
            /* width: 551px; */
            flex-direction: column;
            width: 380px;
            display: flex;
            justify-content: space-around;
            margin-left: 106px;
            .input-group{
                margin-top: 15px;
                .field-set{
                    height: 80px;
                    padding: 10px;
                    border-width: 1px;
                }
            }


            .butons {
                margin-top: 18px;
                margin-bottom: 10px;
                display: flex;
                color: #ffffff;
          
                .checkBoxs{
                  display: block;
                  width: 262px;//248px;
                  margin-bottom: -6px;
                  line-height: 2;
                }
                .download {
                  background-image: linear-gradient(#3E86FF, #2A5CAE, #2A5CAE);
                  text-align: center;
                  cursor: pointer;
                  border-radius: 2px;
                  align-self: flex-end;
                  color: #ffffff;
                  width: 88px;
                  height: 32px;
                  font-size: 14px;
                  font-weight: bold;
                  margin-left: 10px;
                  margin-bottom: 4px;
                  border: none;
                    &:disabled {
                        background-color: darken($color:#20202a, $amount: 20) !important;
                        cursor: default;
                        border: none;
                        color: #808080;
                    }
                }
          
              }
            //   .pure-material-checkbox {
            //     user-select: none;
            //     width: 250px;
            //     position: relative;
            //     display: inline-block;
            //   // color: rgba(var(--on_surface), 0.87);
            //   }
          
            //   /* Input */
            //   .pure-material-checkbox > input {
            //     appearance: none;
            //     z-index: -1;
            //     position: absolute;
            //     left: -10px;
            //     top: -8px;
            //     display: block;
            //     margin: 0;
            //     border-radius: 50%;
            //     width: 40px;
            //     height: 40px;
            //     //background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
            //     box-shadow: none;
            //     outline: none;
            //     opacity: 0;
            //     transform: scale(1);
            //     pointer-events: none;
            //     transition: opacity 0.3s, transform 0.2s;
            //   }
          
            //   /* Span */
            //   .pure-material-checkbox > span {
            //     display: inline-block;
            //     width: 100%;
            //     cursor: pointer;
            //     color: #d8dbe3;
            //     font-style: normal;
            //   }
          
            //   /* Box */
            //   .pure-material-checkbox > span::before {
            //     content: "";
            //     display: inline-block;
            //     box-sizing: border-box;
            //     margin: 6px 11px 3px 1px;
            //     border: solid 2px; /* Safari */
            //     border-color: rgba(255,255,255, 0.24);
            //     border-radius: 2px;
            //     width: 18px;
            //     height: 18px;
            //     vertical-align: top;
            //    // transition: border-color 0.2s, background-color 0.2s;
            //   }
          
            //   /* Checkmark */
            //   .pure-material-checkbox > span::after {
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     top: 6px;
            //     left: 1px;
            //     width: 10px;
            //     height: 5px;
            //     border: solid 2px transparent;
            //     border-right: none;
            //     border-top: none;
            //     transform: translate(3px, 4px) rotate(-45deg);
            //   }
          
              /* Checked, Indeterminate
              .pure-material-checkbox > input:checked {
                background-color: $exportPopupBodyColor  //rgb(var(--pure-material-primary-rgb, 33, 150, 243));
              }
              .pure-material-checkbox > input:disabled + span {
                border-color:  $exportPopupTitlePathFill;  // rgb(var(--pure-material-primary-rgb, 33, 150, 243));
                background-color: $exportPopupBodyBackground; // rgb(var(--pure-material-primary-rgb, 33, 150, 243));
              }
           */
            //   .pure-material-checkbox > input:checked + span::before {
            //     border-color:  $exportPopupTitlePathFill;  // rgb(var(--pure-material-primary-rgb, 33, 150, 243));
            //     background-color: $exportPopupBodyBackground; // rgb(var(--pure-material-primary-rgb, 33, 150, 243));
            //   }
          
          
            //   .pure-material-checkbox > input:checked + span::after {
            //     border-color: $exportPopupTitlePathFill// rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
            //   }

        }
        .epsg-dropdown {
            // width: 100% !important;
            width: 228px !important;
            margin-left: 62px;
            // margin: 5px 0 20px;
        }
    }
   

      .form-radio {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        position: relative;
        // background-color: rgb(92, 98, 115);
        background: url("../../../public/radio-buttons/radio_button.png") left no-repeat;
        color: #ffffff;
        height: 30px;
        width: 30px;
        cursor: pointer;
        outline: none;
      }
      .form-radio:checked::before {
        position: absolute;
        content: url("../../../public/radio-buttons/radio_button-active.png");
        color: #fff;
        font: 12px/2 "Open Sans", sans-serif;
        top: 3px;
      }
      .form-radio:checked {
        background: none;
      }

    .radio-button-group{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        height: 42px;
        padding-top: 10px;
    }

    .fieldset{
        width: 254px;
        border: lightgray solid 1px;
        /* border-width: thin; */
        border-radius: 6px;
    }
    .fileChooseDisabled{
        display: none !important;
    }
}

// .p-dialog-mask.p-component-overlay {
//     pointer-events: auto ;
//   }
//   .p-dialog-visible {
//     display: flex ;
//   }
//   .p-dialog-mask {
//     position: fixed !important;
//     top: 0 !important; 
//     left: 0 !important; 
//     width: 100% !important; 
//     height: 100% !important; 
//     // display: none !important;
//     justify-content: center !important;
//     align-items: center !important;
//     pointer-events: none !important;
//     background-color: transparent !important;
//     transition-property: background-color !important;
//   }
//   .p-component-overlay {
//     position: fixed !important;
//     top: 0 !important;
//     left: 0 !important;
//     /* width: 100%; */
//     /* height: 100%; */
//   }

